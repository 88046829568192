import { action } from "../../../utils/actions";

export const OPEN_NATIONAL_SOCIETY_CORRECT_REPORTS_LIST = action(
  "OPEN_NATIONAL_SOCIETY_CORRECT_REPORTS_LIST",
);
export const OPEN_NATIONAL_SOCIETY_INCORRECT_REPORTS_LIST = action(
  "OPEN_NATIONAL_SOCIETY_INCORRECT_REPORTS_LIST",
);
export const GET_NATIONAL_SOCIETY_CORRECT_REPORTS = action(
  "GET_NATIONAL_SOCIETY_CORRECT_REPORTS",
);
export const GET_NATIONAL_SOCIETY_INCORRECT_REPORTS = action(
  "GET_NATIONAL_SOCIETY_INCORRECT_REPORTS",
);

export const DateColumnName = "date";
