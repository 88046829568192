import { action } from "../../../utils/actions";

export const GET_SUSPECTED_DISEASE = action("GET_SUSPECTED_DISEASE");
export const ADD_SUSPECTED_DISEASE = action("ADD_SUSPECTED_DISEASE");
export const CREATE_SUSPECTED_DISEASE = action("CREATE_SUSPECTED_DISEASE");
export const EDIT_SUSPECTED_DISEASE = action("EDIT_SUSPECTED_DISEASE");
export const OPEN_EDITION_SUSPECTED_DISEASE = action(
  "OPEN_EDITION_SUSPECTED_DISEASE",
);
export const REMOVE_SUSPECTED_DISEASE = action("REMOVE_SUSPECTED_DISEASE");
