import { action } from "../../../utils/actions";

export const OPEN_ALERT_NOT_HANDLED_RECIPIENTS = action(
  "OPEN_ALERT_NOT_HANDLED_RECIPIENTS",
);
export const GET_ALERT_NOT_HANDLED_RECIPIENTS = action(
  "GET_ALERT_NOT_HANDLED_RECIPIENTS",
);
export const CREATE_ALERT_NOT_HANDLED_RECIPIENT = action(
  "CREATE_ALERT_NOT_HANDLED_RECIPIENT",
);
export const EDIT_ALERT_NOT_HANDLED_RECIPIENT = action(
  "EDIT_ALERT_NOT_HANDLED_RECIPIENT",
);
export const REMOVE_ALERT_NOT_HANDLED_RECIPIENT = action(
  "REMOVE_ALERT_NOT_HANDLED_RECIPIENT",
);
export const GET_ALERT_NOT_HANDLED_FORM_DATA = action(
  "GET_ALERT_NOT_HANDLED_FORM_DATA",
);
