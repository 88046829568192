import { action } from "../../../utils/actions";

export const OPEN_ALERT_RECIPIENTS_LIST = action("OPEN_ALERT_RECIPIENTS_LIST");
export const GET_ALERT_RECIPIENTS = action("GET_ALERT_RECIPIENTS");
export const OPEN_ALERT_RECIPIENT_CREATION = action(
  "OPEN_ALERT_RECIPIENT_CREATION",
);
export const OPEN_ALERT_RECIPIENT_EDITION = action(
  "OPEN_ALERT_RECIPIENT_EDITION",
);
export const CREATE_ALERT_RECIPIENT = action("CREATE_ALERT_RECIPIENT");
export const EDIT_ALERT_RECIPIENT = action("EDIT_ALERT_RECIPIENT");
export const REMOVE_ALERT_RECIPIENT = action("REMOVE_ALERT_RECIPIENT");
