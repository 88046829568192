import { action } from "../../../utils/actions";

export const OPEN_PROJECT_ORGANIZATIONS_LIST = action(
  "OPEN_PROJECT_ORGANIZATIONS_LIST",
);
export const GET_PROJECT_ORGANIZATIONS = action("GET_PROJECT_ORGANIZATIONS");
export const OPEN_PROJECT_ORGANIZATION_CREATION = action(
  "OPEN_PROJECT_ORGANIZATION_CREATION",
);
export const CREATE_PROJECT_ORGANIZATION = action(
  "CREATE_PROJECT_ORGANIZATION",
);
export const REMOVE_PROJECT_ORGANIZATION = action(
  "REMOVE_PROJECT_ORGANIZATION",
);
